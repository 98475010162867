import _ from "lodash";
import React from "react";
import contentfulActions from "../actions/contentful";
import "../css/landing.css";
import AssetAlloyPreview from "../img/asset_alloy_preview.png";
import AssetMiddeskPreview from "../img/asset_middesk_preview.png";
import AssetPersonaPreview from "../img/asset_persona_preview.png";
import AssetPlaidPreview from "../img/asset_plaid_preview.png";
import LandingVideoThumbnail from "../img/landing_vid_thumbnail.jpg";
import LogoPacificWestern from "../img/logo_pacific_western.png";
import MobileTDBCNBGroup from "../img/mobile_tdb_cnb_group.png";
import OmnichannelAutomateGroup from "../img/omnichannel_automate.png";
import OmnichannelAutomateGroupMobile from "../img/omnichannel_automate_mobile.png";
import OmnichannelEmpowerGroup from "../img/omnichannel_empower.png";
import OmnichannelEmpowerGroupMobile from "../img/omnichannel_empower_mobile.png";
import OmnichannelExtendGroup from "../img/omnichannel_extend.png";
import OmnichannelExtendGroupMobile from "../img/omnichannel_extend_mobile.png";
import OmnichannelGroup from "../img/omnichannel_image.png";
import OmnichannelGroupMobile from "../img/omnichannel_image_mobile.png";
import CallToAction from "./call_to_action";
import Footer from "./footer";
import Header from "./header";
import HelmetHeader from "./helmetHeader";
import OmnichannelAccordion from "./landing_accordion";
import Lightbox from "./lightbox";
import NewsCallout from "./news_callout";
import SvgLandingHeroBubble from "./svg_landing_hero_bubble";
import SvgPlayArrow from "./svg_play_arrow";

function Customers() {
  var [entries, setEntries] = React.useState({});
  React.useEffect(() => {
    contentfulActions.getEntries({ content_type: "customer" }).then((res) => {
      setEntries(res);
    });
  }, []);

  const orderedEntries = _.orderBy(entries.items, "fields.order");

  const tripledEntries = _.concat(
    orderedEntries,
    orderedEntries,
    orderedEntries
  );

  return (
    <div className="landing-customers">
      <div className="landing-customers-title">
        Powering banks and credit unions with more than $5 trillion in assets
      </div>
      <div className="landing-customers-content">
        <div className="landing-customers-list">
          {_.map(tripledEntries, (entry) => {
            const imageUrl = _.get(
              _.find(_.get(entries, "includes.Asset"), {
                sys: { id: _.get(entry, "fields.image.sys.id") },
              }),
              "fields.file.url"
            );

            return (
              <div className="landing-customer" key={entry.sys.id}>
                <div className="landing-customers-image">
                  <img
                    src={imageUrl}
                    className="landing-customers-logo-img"
                    alt={entry.fields.name}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function Omnichannel() {
  const [openKey, setOpenKey] = React.useState(
    "More than 100 financial onboarding journeys"
  );
  const handleToggle = (key) => {
    setOpenKey(openKey !== key ? key : null);
  };
  const omnichannelData = [
    {
      title: "More than 100 financial onboarding journeys",
      content:
        "Extend your offerings to include deposit accounts, treasury services, credit cards, and over 100 other financial products, providing a single, uniform experience to customers.",
    },
    {
      title: "Extend your core with real-time, synchronous integrations",
      content:
        "Extend your core with seamless technology integration: Prelim’s technology integrates with popular core systems, including Fiserv, FIS, Jack Henry, Finastra, and CSI, enabling real-time searches of existing customer records and the onboarding of new customers.",
    },
    {
      title: "Automate applications with our pre-built library",
      content:
        "Orchestrate over two dozen disparate bank systems and financial apps using our proprietary point-to-point integrations.",
    },
    {
      title: "Empower your team",
      content:
        "Prelim’s modern cloud-based application enables employees to originate, process, and onboard financial applications both in-branch and on the go.",
    },
  ];
  return (
    <div className="landing-omnichannel">
      <div className="landing-omnichannel-content-container">
        <div className="landing-omnichannel-content">
          <div className="landing-omnichannel-title">
            Omnichannel onboarding for banks and credit unions
          </div>
          <div className="landing-omnichannel-content-top">
            <div className="landing-omnichannel-lhs">
              <div className="landing-omnichannel-accordion">
                {omnichannelData.map((item) => (
                  <OmnichannelAccordion
                    key={item.title}
                    title={item.title}
                    content={item.content}
                    active={openKey === item.title}
                    toggle={handleToggle}
                  />
                ))}
              </div>
            </div>
            <div className="landing-omnichannel-rhs">
              {openKey === "More than 100 financial onboarding journeys" && (
                <img
                  src={OmnichannelGroup}
                  className="landing-tailored-omnichannel-img"
                />
              )}
              {openKey === "More than 100 financial onboarding journeys" && (
                <img
                  src={OmnichannelGroupMobile}
                  className="landing-tailored-omnichannel-img-mobile"
                />
              )}
              {openKey ===
                "Extend your core with real-time, synchronous integrations" && (
                <img
                  src={OmnichannelExtendGroup}
                  className="landing-tailored-omnichannel-img"
                />
              )}
              {openKey ===
                "Extend your core with real-time, synchronous integrations" && (
                <img
                  src={OmnichannelExtendGroupMobile}
                  className="landing-tailored-omnichannel-img-mobile"
                />
              )}
              {openKey ===
                "Automate applications with our pre-built library" && (
                <img
                  src={OmnichannelAutomateGroup}
                  className="landing-tailored-omnichannel-img-automate"
                />
              )}
              {openKey ===
                "Automate applications with our pre-built library" && (
                <img
                  src={OmnichannelAutomateGroupMobile}
                  className="landing-tailored-omnichannel-img-mobile-automate"
                />
              )}
              {openKey === "Empower your team" && (
                <img
                  src={OmnichannelEmpowerGroup}
                  className="landing-tailored-omnichannel-img"
                />
              )}
              {openKey === "Empower your team" && (
                <img
                  src={OmnichannelEmpowerGroupMobile}
                  className="landing-tailored-omnichannel-img-mobile"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Tailored() {
  return (
    <div className="landing-tailored">
      <div className="landing-tailored-content-bottom">
        <div className="landing-tailored-content">
          <div className="landing-tailored-content-inner-bottom">
            <div className="landing-tailored-lhs">
              <div className="landing-tailored-content-inner">
                <div className="landing-tailored-category">
                  Tailor your financial journeys with our easy to use tools
                </div>
                <div className="landing-tailored-description-container">
                  <div className="landing-tailored-description">
                    <br />
                    {`With Prelim LaunchPad, our white-labeled solution. It's
                    designed to align with your brand's unique image and
                    identity.`}
                    <br />
                    <br />
                    {`Prelim’s intuitive builder tools empower your employees to easily update and configure the app's system integrations, design, brand, products, workflows, and decisioning criteria to deliver a first-class user experience.`}
                  </div>
                </div>
              </div>
            </div>
            <div className="landing-tailored-rhs">
              <div className="landing-tailored-image">
                <img
                  src={MobileTDBCNBGroup}
                  className="landing-tailored-mobile-tdb"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="landing-tailored-content">
        <div className="landing-tailored-title">
          {`Tailored to your bank's image`}
          <a className="landing-tailored-button" href="/signup">
            Talk to an expert
          </a>
        </div>
      </div>
    </div>
  );
}

function Compliant() {
  return (
    <div className="landing-compliant">
      <div className="landing-compliant-content"></div>
      <div className="landing-compliant-content">
        <div className="landing-compliant-content-bottom">
          <div className="landing-compliant-lhs">
            <div className="landing-compliant-preview-container">
              <img
                src={AssetAlloyPreview}
                className="landing-compliant-alloy-img"
              />
              <img
                src={AssetPlaidPreview}
                className="landing-compliant-plaid-img"
              />
              <img
                src={AssetPersonaPreview}
                className="landing-compliant-persona-img"
              />
              <img
                src={AssetMiddeskPreview}
                className="landing-compliant-middesk-img"
              />
            </div>
          </div>
          <div className="landing-compliant-rhs">
            <div className="landing-compliant-copy">
              <div className="landing-compliant-category">
                Compliant & Secure
              </div>
              <div className="landing-compliant-description-container">
                <div className="landing-compliant-description">
                  Prelim partners with Alloy, Middesk, Chexsystems, LexisNexis,
                  Persona, and has dozens more drop-in integrations that ensure
                  your customer information is compliant and secure.
                  <br />
                  <br />
                  Prelim is SOC 2 Type 1 Certified and SOC 2 Type 2 Certified
                  and is compliant with HIPAA, FCRA, and GLBA regulations.
                  <br />
                  <br />
                  Using point-to-point integrations, Prelim orchestrates and
                  automates KYC/KYB/AML in real time.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Solutions() {
  return (
    <div className="landing-solutions">
      <div className="landing-solutions-content">
        <div className="landing-solutions-business-account">
          <div className="landing-solutions-content-inner">
            <div className="landing-solutions-business-account-title">
              Business Account Opening
            </div>
            <a
              href="/business-account-opening"
              className="landing-solutions-link"
            >
              Learn more
            </a>
          </div>
        </div>
        <div className="landing-solutions-consumer-account">
          <div className="landing-solutions-content-inner">
            <div className="landing-solutions-consumer-account-title">
              Consumer Account Opening
            </div>
            <a
              href="/consumer-account-opening"
              className="landing-solutions-link"
            >
              Learn more{" "}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function Hero() {
  var [email, setEmail] = React.useState("");
  var [modalIsOpen, setModalIsOpen] = React.useState(false);
  function handleChange(event) {
    setEmail(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    window.location.href = `/signup?email=${email}`;
  }

  const videoUrl = "https://player.vimeo.com/video/893464397?h=725dde136b";

  const handleClickVideo = () => {
    setModalIsOpen(true);
  };

  return (
    <div className="landing-hero">
      <Lightbox
        videoUrl={videoUrl}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        closeModal={() => setModalIsOpen(false)}
      />
      <SvgLandingHeroBubble className="landing-hero-bubble" />
      <div className="landing-hero-content">
        <div className="landing-hero-title">
          <div>
            Your all-in-one platform<br></br> for financial originations
          </div>
        </div>
        <div className="landing-hero-container">
          <div className="landing-hero-container-lhs">
            <div className="landing-hero-copy-container">
              <div className="landing-hero-description">
                <b>Sell</b> any financial product or service.
                <br />
                <br />
                <b>Automate</b> processing, reviewing, and decisioning.
                <br />
                <br />
                <b>Onboard</b> to your existing core, loan origination system,
                and more.
              </div>
            </div>
            <div className="landing-hero-form-container">
              <form className="landing-hero-form" onSubmit={handleSubmit}>
                <input
                  className="landing-hero-email-input"
                  placeholder="Enter your work email"
                  type="text"
                  onChange={handleChange}
                />
                <button
                  className="landing-hero-button"
                  type="Submit"
                  onClick={handleSubmit}
                >
                  Talk to an expert
                </button>
              </form>
            </div>
          </div>
          <div
            className="business-account-opening-hero-video"
            onClick={handleClickVideo}
          >
            <img
              className="business-account-opening-hero-video-thumbnail"
              src={LandingVideoThumbnail}
            />
            <div className="business-account-opening-hero-video-button" />
            <div className="business-account-opening-hero-video-button-inner" />
            <SvgPlayArrow className="business-account-opening-hero-button-image" />
          </div>
        </div>
      </div>
    </div>
  );
}

function Testimonial({ ready }) {
  if (!ready) return null;
  const quote =
    "Prelim powers the next generation of digital transformation and growth at our bank.";
  const name = "Susan Tang";
  const title = "EVP, Head of Treasury Sales & Product at Pacific Western Bank";
  return (
    <Testimonial
      quote={quote}
      logoImg={LogoPacificWestern}
      name={name}
      title={title}
      quoteeImg={""}
    />
  );
}

function Landing() {
  var title =
    "Prelim: Helping financial institutions digitize their customer experience.";
  var description =
    "Prelim is the #1 platform for financial institutions to work with customers digitally. Go digital with zero code using one white-labeled, all-in one platform that integrates with your current technology stack.";
  return (
    <div>
      <HelmetHeader title={title} description={description} />
      <Header />
      <Hero />
      <Customers />
      <Solutions />
      <Omnichannel />
      <Testimonial />
      <Tailored />
      <Compliant />
      <NewsCallout />
      <CallToAction />
      <Footer />
    </div>
  );
}

export default Landing;
